<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicles">Fahrzeuge</router-link></li>
              <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">{{ vehicle.license_plate }}</router-link></li>
              <li>Reservierungen</li>
            </ul>
            <div class="header-actions">
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">Stammdaten</router-link></li>
            <li class="active"><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/reservations'">Reservierungen</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/damages'">Schäden</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/repairs'">Reparaturen</router-link></li>
            <!--<li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/insurance'">Versicherung</router-link></li>-->
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/documents'">Dokumente</router-link></li>
          </ul>

          <div class="admin-body">

            <div v-if="reservations.length == 0">
              <p>Noch keine Reservierungen.</p>
            </div>
            <div v-else>

              <table class="table">
                <thead>
                  <tr>
                    <th width="10%">Status</th>
                    <th width="25%">Abholung & Rückgabe</th>
                    <th width="15%">Fahrzeug</th>
                    <th width="25%">Kunde</th>
                    <th width="15%">Betrag</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="reservation in reservations" v-bind:key="reservation.id">
                    <td>
                      <span v-if="reservation.status == 'booked'" class="badge badge-yellow">Offen</span>
                      <span v-if="reservation.status == 'paid'" class="badge badge-green">Bezahlt</span>
                      <span v-if="reservation.status == 'canceled'" class="badge badge-red">Storniert</span>
                      <span v-if="reservation.status == 'confirmed'" class="badge badge-green">Bestätigt</span>
                      <span v-if="reservation.status == 'ended'" class="badge badge-black">Beendet</span>
                    </td>
                    <td>{{ reservation.pickup_timestamp }}<br>{{ reservation.return_timestamp }}</td>
                    <td>
                      <span v-if="reservation.vehicle">{{ reservation.vehicle.license_plate }}</span>
                    </td>
                    <td>{{ reservation.first_name }} {{ reservation.last_name }}</td>
                    <td>{{ parseFloat(reservation.sum_gross).toFixed(2) }} €</td>
                    <td style="text-align:right">
                      <router-link :to="'/admin/reservations/'+reservation.id">
                        <span class="material-icons">create</span>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'

export default {
  name: 'admin_vehicle_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      errors: [],
      vehicle: {},
      reservations: []
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle = response.data.vehicle;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/reservations?vehicle_id='+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.reservations = response.data.reservations;
      })
      .catch(error => {
        console.log(error);
      })

    },
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
